<template>
  <div class="home">
    <v-row>
      <v-col cols="6">
        <v-card outlined rounded="lg" class="pa-6 width100">
          <div class="f18 font-weight-bold">Total users</div>
          <div class="d-flex align-center mt-1">
            <div class="font-weight-bold f36">{{ counters.users_total }}</div>
            <div class="status ml-4 success lighten-5 success--text" v-if="counters.users_new">
              <v-icon color="success" class="mr-1">mdi-arrow-top-right</v-icon> {{ counters.users_new }} users today
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined rounded="lg" class="pa-6 width100">
          <div class="f18 font-weight-bold">Total cars</div>
          <div class="d-flex align-center mt-1">
            <div class="font-weight-bold f36">{{ counters.cars_total }}</div>
            <div class="status ml-4 success lighten-5 success--text" v-if="counters.cars_new">
              <v-icon color="success" class="mr-1">mdi-arrow-top-right</v-icon> {{ counters.cars_new }} cars today
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card outlined rounded="lg" class="pa-6 pb-5 width100" min-height="160">
          <div class="f15 font-weight-bold">Total posts</div>
          <div class="d-flex align-center mt-2">
            <div class="font-weight-bold f30">{{ counters.posts_total }}</div>
            <div class="status ml-4 success lighten-5 success--text" v-if="counters.posts_new">
              <v-icon color="success" class="mr-1">mdi-arrow-top-right</v-icon> {{ counters.posts_new }} posts today
            </div>
          </div>
          <div class="f15 mt-4 gray--text text-600">
            Total comments: <span class="black--text font-weight-bold"> {{ counters.posts_comments_total }}</span>
          </div>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card outlined rounded="lg" class="pa-6 pb-5 width100" min-height="160">
          <div class="f15 font-weight-bold">Total questions</div>
          <div class="d-flex align-center mt-2">
            <div class="font-weight-bold f30">{{ counters.questions_total }}</div>
            <div class="status ml-4 success lighten-5 success--text" v-if="counters.questions_new">
              <v-icon color="success" class="mr-1">mdi-arrow-top-right</v-icon> {{ counters.questions_new }} questions today
            </div>
          </div>
          <div class="f15 mt-4 gray--text text-600">
            Total answers: <span class="black--text font-weight-bold"> {{ counters.answers_total }}</span>
          </div>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card outlined rounded="lg" class="pa-6 pb-5 width100" min-height="160">
          <div class="f15 font-weight-bold">Total wheels</div>
          <div class="d-flex align-center mt-2">
            <div class="font-weight-bold f30">{{ counters.wheels_total }}</div>
            <div class="status ml-4 success lighten-5 success--text" v-if="counters.wheels_new">
              <v-icon color="success" class="mr-1">mdi-arrow-top-right</v-icon> {{ counters.wheels_new }} wheels today
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-card outlined rounded="lg" class="px-4 pt-6 mt-4 total">
      <div class="d-flex align-center">
        <div class="f18 font-weight-bold">Total users</div>
        <v-spacer></v-spacer>
        <v-select
          v-model="totalPeriod"
          style="max-width: 210px"
          hide-details
          :items="periodList"
          @change="getDataTotal"
          item-text="name"
          item-value="id"
          outlined
          dense
          required
          filled
          color="primary"
        >
          <template v-slot:prepend-inner>
            <v-icon color="gray lighten-1">mdi-calendar-blank-outline</v-icon>
          </template>
        </v-select>
        <v-btn depressed height="39" width="141" :to="`/metrics?type=users&period=${totalPeriod}`" class="primary ml-4">
          <span class="f15 text-600">View more</span>
        </v-btn>
      </div>
      <v-chartist type="Line" :data="{ labels: total.labels, series: [total.series] }" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="px-4 pt-6 mt-4 reg">
      <div class="d-flex align-center">
        <div class="f18 font-weight-bold">Users registration</div>
        <v-spacer></v-spacer>
        <v-select
          v-model="regPeriod"
          style="max-width: 210px"
          hide-details
          :items="periodList"
          @change="getDataReg"
          item-text="name"
          item-value="id"
          outlined
          dense
          required
          filled
          color="primary"
        >
          <template v-slot:prepend-inner>
            <v-icon color="gray lighten-1">mdi-calendar-blank-outline</v-icon>
          </template>
        </v-select>
        <v-btn depressed height="39" width="141" :to="`/metrics?type=users&period=${regPeriod}`" class="primary ml-4">
          <span class="f15 text-600">View more</span>
        </v-btn>
      </div>
      <v-chartist type="Bar" :data="reg" :options="chartOptions"></v-chartist>
    </v-card>
    <div class="mt-12">
      <v-btn
        height="41"
        class="px-4"
        :depressed="table != i"
        :outlined="table != i"
        @click="table == i ? '' : ((table = i), getTable())"
        :class="[i == 1 ? 'mx-3' : '', table == i ? 'pink white--text' : '']"
        v-for="(item, i) in tableList"
        :key="item"
      >
        Trending {{ item }}: Top 20
      </v-btn>
    </div>
    <div class="d-flex align-center mt-7 mb-3">
      <div class="font-weight-bold text-h6">Tranding {{ tableList[table] }}: Top 20</div>
      <v-spacer></v-spacer>
      <v-select
        v-model="tablePeriod"
        class="white"
        style="max-width: 210px"
        hide-details
        @change="getTable"
        :items="periodListTable"
        item-text="name"
        item-value="id"
        outlined
        dense
        required
        color="primary"
      >
        <template v-slot:prepend-inner>
          <v-icon color="gray lighten-1">mdi-calendar-blank-outline</v-icon>
        </template>
      </v-select>
      <v-btn depressed height="39" width="141" :to="`/metrics?type=${tableList[table]}&period=${tablePeriod}`" class="primary ml-4">
        <span class="f15 text-600">View more</span>
      </v-btn>
    </div>
    <v-data-table
      v-if="table == 0"
      hide-default-footer
      fixed-header
      :headers="headersTopics"
      :items="data"
      item-key="id"
      :items-per-page="20"
    >
      <template v-slot:no-data v-if="!data.length">
        <div class="d-flex flex-column align-center justify-center height100 text-center" style="height: 300px">
          <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">Sorry, no data</div>
        </div>
      </template>
      <template v-slot:body="{ items }" v-else>
        <tbody>
          <tr v-for="(item, i) in items" :key="item.id" height="56">
            <td>{{ i + 1 }}</td>
            <td>
              <div class="d-flex align-center">
                <v-img v-if="item.icon" class="mr-2" height="24" contain max-width="24" :src="item.icon" alt="logo"></v-img>{{ item.title }}
              </div>
            </td>
            <td>{{ item.category ? item.category.title : '' }}</td>
            <td>{{ item.used_count_posts > 1000 ? `${item.used_count_posts / 1000}k` : item.used_count_posts }}</td>
            <td>{{ item.used_count_wheels > 1000 ? `${item.used_count_wheels / 1000}k` : item.used_count_wheels }}</td>
            <td>{{ item.used_count_questions > 1000 ? `${item.used_count_questions / 1000}k` : item.used_count_questions }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-data-table
      v-if="table == 1"
      hide-default-footer
      fixed-header
      :headers="headersHashtags"
      :items="data"
      item-key="id"
      :items-per-page="20"
    >
      <template v-slot:no-data v-if="!data.length">
        <div class="d-flex flex-column align-center justify-center height100 text-center" style="height: 300px">
          <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">Sorry, no data</div>
        </div>
      </template>
      <template v-slot:body="{ items }" v-else>
        <tbody>
          <tr v-for="(item, i) in items" :key="item.id" height="56">
            <td>{{ i + 1 }}</td>
            <td>#{{ item.hashtag }}</td>
            <td>{{ item.used_count_posts > 1000 ? `${item.used_count_posts / 1000}k` : item.used_count_posts }}</td>
            <td>{{ item.used_count_wheels > 1000 ? `${item.used_count_wheels / 1000}k` : item.used_count_wheels }}</td>
            <td>{{ item.used_count_questions > 1000 ? `${item.used_count_questions / 1000}k` : item.used_count_questions }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-data-table
      v-if="table == 2"
      hide-default-footer
      fixed-header
      :headers="headersSearches"
      :items="data"
      item-key="id"
      :items-per-page="20"
    >
      <template v-slot:no-data v-if="!data.length">
        <div class="d-flex flex-column align-center justify-center height100 text-center" style="height: 300px">
          <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">Sorry, no data</div>
        </div>
      </template>
      <template v-slot:body="{ items }" v-else>
        <tbody>
          <tr v-for="(item, i) in items" :key="item.id" height="56">
            <td>{{ i + 1 }}</td>
            <td>{{ item.phrase }}</td>
            <td class="text-end">{{ item.used > 1000 ? `${item.used / 1000}k` : item.used }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import VueChartist from 'v-chartist';

export default {
  components: {
    'v-chartist': VueChartist,
  },
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      key: 1,
      totalPeriod: '1w',
      regPeriod: '1w',
      tablePeriod: '1w',
      periodList: [
        { id: '1w', name: 'This week' },
        { id: '1m', name: 'This month' },
        { id: '1q', name: 'This quarter' },
        { id: '1y', name: 'This year' },
      ],
      periodListTable: [
        { id: '1w', name: 'This week' },
        { id: '1m', name: 'This month' },
        { id: '1q', name: 'This quarter' },
        { id: '1y', name: 'This year' },
        { id: 'ever', name: 'Ever' },
      ],
      total: { series: [] },
      reg: {},
      chartOptions: {
        height: '260px',
        fullWidth: true,
        distributeSeries: true,
        lineSmooth: false,
        showPoint: false,
        chartPadding: {
          right: 30,
        },
        axisX: {
          showGrid: false,
        },
      },
      table: 0,
      tableList: ['topics', 'hashtags', 'searches'],
      data: [],
      headersTopics: [
        { text: '#', value: 'i', sortable: false, class: 'width0' },
        { text: 'Topic', value: 'title', sortable: false },
        { text: 'Category', value: 'category.title', sortable: false },
        { text: 'Using: Posts', value: 'used_count_posts', sortable: false },
        { text: 'Using: Wheels', value: 'used_count_wheels', sortable: false },
        { text: 'Using: Questions', value: 'used_count_questions', sortable: false },
      ],
      headersHashtags: [
        { text: '#', value: 'i', sortable: false, class: 'width0' },
        { text: 'Hashtag', value: 'hashtag', sortable: false },
        { text: 'Using: Posts', value: 'used_count_posts', sortable: false },
        { text: 'Using: Wheels', value: 'used_count_wheels', sortable: false },
        { text: 'Using: Questions', value: 'used_count_questions', sortable: false },
      ],
      headersSearches: [
        { text: '#', value: 'i', sortable: false, class: 'width0' },
        { text: 'Search phrase', value: 'phrase', sortable: false },
        { text: 'Using', value: 'used', sortable: false, class: 'text-end' },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('getMetricDashboardCounter');
    this.getDataTotal();
    this.getDataReg();
    this.getTable();
  },
  methods: {
    getDataTotal() {
      this.$store
        .dispatch('getMetricPageStatistics', {
          page: 'users',
          period: this.totalPeriod,
          from: '',
          to: '',
          type: 'total',
        })
        .then((res) => {
          this.total = res.data;
        });
    },
    getDataReg() {
      this.$store
        .dispatch('getMetricPageStatistics', {
          page: 'users',
          period: this.regPeriod,
          from: '',
          to: '',
          type: 'new',
        })
        .then((res) => {
          this.reg = res.data;
        });
    },
    getTable() {
      this.$store
        .dispatch('getMetricPageStatistics', {
          page: this.tableList[this.table],
          period: this.tablePeriod,
          from: '',
          to: '',
          type: 'tranding',
        })
        .then((res) => {
          this.data = res.data;
        });
    },
  },
  computed: {
    counters() {
      return this.$store.getters.metricDashboardCounter;
    },
  },
};
</script>

<style lang="scss">
#app .home {
  .status {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 5px 16px 6px 10px;
    border-radius: 16px;
  }
  .total,
  .reg {
    .ct-bar,
    .ct-point,
    .ct-line {
      stroke: var(--v-primary-base);
    }
  }
  .reg {
    .ct-bar {
      stroke-width: 20px;
    }
  }
  .v-btn--is-elevated {
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.18);
  }
  .v-btn--outlined {
    border: thin solid var(--v-gray-lighten2) !important;
  }
}
</style>
